import Canvi from '../canvi/canvi-webpack';

export default class Navigation {
    constructor() {
        if (document.getElementById('canvi-navbar')) {
            var canviRight = new Canvi({
                content: '.canvi-content',
                navbar: '#canvi-navbar',
                openButton: '.canvi-open-button',
                position: 'right',
                width: '280px'
            });

            let menuButton = document.getElementById('menuButton');
            let body = document.querySelector('body');

            body.addEventListener('canvi.after-open', (e) => {
                menuButton.classList.add('is-active');
            });

            body.addEventListener('canvi.after-close', (e) => {
                menuButton.classList.remove('is-active');

                // reset fixed navbar
                var content = document.querySelectorAll('.canvi-content');
                if (content.length) {
                    content[0].style.transform = '';
                }
            });

            document.querySelectorAll('.canvi-navbar ul li > a').forEach(link => {
                link.addEventListener('click', function() {
                    canviRight.close();
                });
            });
        }

        if ('IntersectionObserver' in window) {
            /* Service nav hide/unhide */
            let el = document.getElementById('main-nav-wrapper');
            this.stickyNav(el);
        }
    }

    stickyNav(el) {
        el.style.height = el.offsetHeight +'px';

        const observer = new IntersectionObserver(
            ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
            { threshold: [1] }
        );

        observer.observe(el);
    }
}
