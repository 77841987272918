import 'alpinejs'
import Axios from 'axios';

window.events = function() {
    return {
        events: [],
        loading: true,

        initialize() {
            if (typeof window.moment !== "undefined") {
                this.fetchEvents();
                moment.locale(window.appConfig.locale)
            }
        },

        formatDate(datestr) {
            let date = moment(datestr, moment.DATETIME_LOCAL);

            return date.format('dd D MMM H:mm');
        },

        fetchEvents() {
            let options = {
                url: '/api/events/list.json',
                headers: {
                    'X-CSRF-Token': window.appConfig.token,
                    'X-Site-locale': window.appConfig.locale
                }
            };

            Axios(options)
                .then((response) => {
                    if (response.data.data) {
                        this.events = response.data.data.events;
                        this.loading = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
}
