export default class TableNav {
    constructor() {
        this.table = document.getElementById('comp-table');

        document.querySelectorAll('.comp-btn').forEach(btn => {
            btn.addEventListener('click', e => {
                e.preventDefault();
            });
        });

        if (!this.table) {
            return;
        }

        this.buttons = document.querySelectorAll('.comp-btn');
        this.classes = this.table.dataset.items.split(',');

        if (this.table.dataset && this.table.dataset.classes) {
            this.classes = this.table.dataset.classes.split(',');
        }

        this.current = 't1';
        this.items = document.querySelectorAll('#comp-table .prop');
        this.buttons.forEach(btn => {
            this.btnEvent(btn);
        });
    }

    btnEvent (btn) {
        btn.addEventListener('click', e => {
            e.preventDefault();

            let targetIndex;
            let currentIndex = this.classes.indexOf(this.current);

            if (btn.classList.contains('next')) {
                targetIndex = (currentIndex + 1) % this.classes.length;
            }

            if (btn.classList.contains('prev')) {
                targetIndex = (currentIndex - 1) % this.classes.length;
            }

            if (targetIndex >= 0) {
                const nextClass = this.classes[targetIndex];

                this.items.forEach(item => {
                    if (item.classList.contains(nextClass)) {
                        item.classList.add('visible');
                        this.current = nextClass;
                    } else {
                        item.classList.remove('visible');
                    }
                });
            }
        });
    }
}
