export default class ProductHighlight {
    constructor() {
        let bullets = document.getElementsByClassName('bullet');
        let tiles = document.getElementsByClassName('tile');
        let joyXtra = document.getElementById('joy_xtra');

        if (bullets.length) {

            const resetRadios = function () {
                for (let x = 0; x < bullets.length; x++) {
                    bullets[x].classList.remove('selected');
                    bullets[x].querySelector(".icon img").src = '/img/products/pages/overview/icon-unchecked.png';
                }
            };

            const resetHighlights = function () {
                for (let y = 0; y < tiles.length; y++) {
                    tiles[y].classList.remove('downlight');

                    // reset order
                    if (!tiles[y].classList.contains('compare')) {
                        tiles[y].classList.remove('order-1');
                        tiles[y].classList.remove('order-2');
                        tiles[y].classList.remove('order-3');
                        tiles[y].classList.remove('order-4');
                        tiles[y].classList.remove('order-5');
                        tiles[y].classList.remove('order-6');
                    }

                    if (tiles[y].id == 'joy') {
                        tiles[y].classList.add('order-1');
                    }
                    if (tiles[y].id == 'joy_xtra') {
                        tiles[y].classList.add('order-2');
                    }
                    if (tiles[y].id == 'explore3') {
                        tiles[y].classList.add('order-3');
                    }
                    if (tiles[y].id == 'maker3') {
                        tiles[y].classList.add('order-4');
                    }

                    if (tiles[y].id == 'ep_s') {
                        tiles[y].classList.add('order-1');
                    }
                    if (tiles[y].id == 'ep_m') {
                        tiles[y].classList.add('order-2');
                    }
                    if (tiles[y].id == 'ep_l') {
                        tiles[y].classList.add('order-3');
                    }
                    if (tiles[y].id == 'mugpress') {
                        tiles[y].classList.add('order-4');
                    }
                    if (tiles[y].id == 'hatpress') {
                        tiles[y].classList.add('order-5');
                    }
                    if (tiles[y].id == 'autopress') {
                        tiles[y].classList.add('order-6');
                    }
                }
            };

            for (let i = 0; i < bullets.length; i++) {

                bullets[i].addEventListener('click', function () {

                    // scroll to title
                    let target = document.querySelector('#products-top');
                    let headerOffset = 40;
                    let elementPosition = target.offsetTop;
                    let offsetPosition = elementPosition - headerOffset;
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth"
                    });

                    // reset highlights
                    resetHighlights();

                    // get specific bullet class
                    var bulletClass = '';
                    for (let j = 0; j < this.classList.length; j++) {
                        var clss = this.classList[j];
                        if (clss.includes('bullet-')) {
                            bulletClass = this.classList[j];
                            break;
                        }
                    }

                    var img = this.querySelector(".icon img");

                    // check if button is (un)selected
                    if (this.classList.contains('selected')) {
                        this.classList.remove('selected');

                        // swap img
                        img.src = '/img/products/pages/overview/icon-unchecked.png';

                        resetHighlights();

                    } else {
                        resetRadios();

                        this.classList.add('selected');

                        if(typeof(document.getElementById('maker3')) != 'undefined' && document.getElementById('maker3') != null) {
                            // if third bullet is selected => move maker blocks to first & second position
                            document.getElementById('joy').classList.remove('order-1');
                            if (joyXtra) {
                                document.getElementById('joy_xtra').classList.remove('order-2');
                            }
                            document.getElementById('explore3').classList.remove('order-3');
                            document.getElementById('maker3').classList.remove('order-4');

                            switch (i) {
                                case 1:
                                    document.getElementById('joy').classList.add('order-2');
                                    if (joyXtra) {
                                        document.getElementById('joy_xtra').classList.add('order-1');
                                    }
                                    document.getElementById('explore3').classList.add('order-3');
                                    document.getElementById('maker3').classList.add('order-4');
                                    break;
                                case 2:
                                    document.getElementById('joy').classList.add('order-1');
                                    if (joyXtra) {
                                        document.getElementById('joy_xtra').classList.add('order-4');
                                    }
                                    document.getElementById('explore3').classList.add('order-2');
                                    document.getElementById('maker3').classList.add('order-3');
                                    break;
                                case 3:
                                    document.getElementById('joy').classList.add('order-2');
                                    if (joyXtra) {
                                        document.getElementById('joy_xtra').classList.add('order-3');
                                    }
                                    document.getElementById('explore3').classList.add('order-4');
                                    document.getElementById('maker3').classList.add('order-1');
                                    break;
                                default:
                                    break;
                            }
                        }

                        if(typeof(document.getElementById('autopress')) != 'undefined' && document.getElementById('autopress') != null) {
                            document.getElementById('ep_s').classList.remove('order-1');
                            document.getElementById('ep_m').classList.remove('order-2');
                            document.getElementById('ep_l').classList.remove('order-3');
                            document.getElementById('mugpress').classList.remove('order-4');
                            document.getElementById('hatpress').classList.remove('order-5');
                            document.getElementById('autopress').classList.remove('order-6');

                            if (i == 4) {
                                // if fifth bullet is selected => move autopress block to first position
                                document.getElementById('ep_s').classList.add('order-2');
                                document.getElementById('ep_m').classList.add('order-3');
                                document.getElementById('ep_l').classList.add('order-4');
                                document.getElementById('mugpress').classList.add('order-5');
                                document.getElementById('hatpress').classList.add('order-6');
                                document.getElementById('autopress').classList.add('order-1');
                            } else if (i == 3) {
                                // if fourth bullet is selected => move hatpress block to first position
                                document.getElementById('ep_s').classList.add('order-2');
                                document.getElementById('ep_m').classList.add('order-3');
                                document.getElementById('ep_l').classList.add('order-4');
                                document.getElementById('mugpress').classList.add('order-5');
                                document.getElementById('hatpress').classList.add('order-1');
                                document.getElementById('autopress').classList.add('order-6');
                            } else if (i == 2) {
                                // if third bullet is selected => move mugpress block to first position
                                document.getElementById('ep_s').classList.add('order-2');
                                document.getElementById('ep_m').classList.add('order-3');
                                document.getElementById('ep_l').classList.add('order-4');
                                document.getElementById('mugpress').classList.add('order-1');
                                document.getElementById('hatpress').classList.add('order-5');
                                document.getElementById('autopress').classList.add('order-6');
                            } else if (i == 1) {
                                // if second bullet is selected => move ep medium & large blocks to first & second position
                                document.getElementById('ep_m').classList.add('order-1');
                                document.getElementById('ep_l').classList.add('order-2');
                                document.getElementById('ep_s').classList.add('order-4');
                                document.getElementById('mugpress').classList.add('order-5');
                                document.getElementById('hatpress').classList.add('order-6');
                                document.getElementById('autopress').classList.add('order-3');
                            } else {
                                document.getElementById('ep_s').classList.add('order-1');
                                document.getElementById('ep_m').classList.add('order-2');
                                document.getElementById('ep_l').classList.add('order-3');
                                document.getElementById('mugpress').classList.add('order-4');
                                document.getElementById('hatpress').classList.add('order-5');
                                document.getElementById('autopress').classList.add('order-6');
                            }
                        }

                        // swap img
                        img.src = '/img/products/pages/overview/icon-checked.png';

                        // highlight products
                        for (let l = 0; l < tiles.length; l++) {
                            if (tiles[l].classList.contains(bulletClass)) {
                                tiles[l].classList.remove('downlight');
                            } else {
                                tiles[l].classList.add('downlight');
                            }
                        }
                    }
                });
            }
        }
    }
}
