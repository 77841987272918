import axios from 'axios';

export default class Trainings {
    constructor() {
        this.countrySelect();
    }

    // Country select on trainings page
    countrySelect() {
        const countrySelect = document.getElementById('country-code');
        const boughtAtSelect = document.getElementById('bought-at');
        const otherField = document.getElementById('boughtAtOther');

        if (countrySelect) {

            const otherFieldChecker = function(select) {
                if (select.value == 'other') {
                    otherField.classList.add('active');
                    otherField.setAttribute('required', 'required');
                } else {
                    otherField.classList.remove('active');
                    otherField.removeAttribute('required');
                }
            };

            countrySelect.addEventListener('change', (e) => {
                var code = e.target.value;

                axios.get('/api/trainings/reseller_list.json?region=' + code)
                    .then((response) => {
                        if (response.data && response.data.stores) {

                            let other;

                            for (let opt of boughtAtSelect.querySelectorAll('option')) {
                                if (opt.value == 'other') {
                                    other = opt.cloneNode(true);
                                    opt.remove();
                                }

                                if (opt.value) {
                                    opt.remove();
                                } else {
                                    opt.selected = true;
                                }
                            }

                            otherFieldChecker(boughtAtSelect);

                            Object.keys(response.data.stores).forEach(k => {
                                let option = document.createElement('option');

                                option.value = response.data.stores[k];
                                option.text = response.data.stores[k];
                                boughtAtSelect.appendChild(option);
                            });

                            boughtAtSelect.appendChild(other);
                        }
                    });
            });

            otherFieldChecker(boughtAtSelect)

            boughtAtSelect.addEventListener('change', (e) => {
                otherFieldChecker(e.target);
            });
        }
    }
}
