// global modules
import Navigation from './modules/navigation';
import Trainings from './modules/trainings';
import TableNav from './modules/table_nav';
import SignupButtons from './modules/signup_buttons';
import CountryPicker from './modules/country_picker';
import ReviewSlider from './modules/review_slider';
import GLightbox from 'glightbox';
import ProductHighlight from './modules/product_highlight';
import FAQ from './modules/faq';

// alpine modules
import './modules/wishlist';
import './modules/events';
// import './modules/events_studio';

// other basic functionality
new Navigation;
new Trainings;
new TableNav;
new SignupButtons;
new CountryPicker;
new ReviewSlider;
new ProductHighlight;
new FAQ;

window.glightbox = new GLightbox;

import './modules/cookie_eu_banner';

// open external links in new window
[...document.links].forEach(link => {
    if (link.hostname != 'www.start2cricut.com' && link.hostname != window.location.hostname) {
        link.target = '_blank'
    }
});
