import { tns } from 'tiny-slider/src/tiny-slider';

export default class ReviewSlider {
    constructor() {
        /** REVIEW SLIDER */
        if (document.getElementById('slider-reviews')) {
            var slider = tns({
                container: '.review-slider',
                items: 1,
                gutter: 20,
                slideBy: 'page',
                edgePadding: 50,
                controls: true,
                controlsText: [ '<span></span>','<span></span>'],
                navPosition: 'bottom',
                mouseDrag: true,
                responsive: {
                    700: {
                    items: 2,
                    edgePadding: 50
                    },
                    900: {
                    items: 3,
                    edgePadding: 50
                    },
                    1100: {
                    items: 4,
                    gutter: 20,
                    edgePadding: 50
                    }
                }
            });
        }
    }
}
