export default class SignupButtons {
    constructor() {
        /** TRAININGS SELECTOR */
        let signupButtons = document.querySelectorAll('a[data-training-id]');

        if (signupButtons.length) {
            let trainingSelect = document.getElementById('training-id');

            signupButtons.forEach(button => {
                button.addEventListener('click', function(event) {

                    let targetId = button.getAttribute('data-training-id');

                    trainingSelect.value = targetId;
                });
            });
        }

        let completeWrapper = document.getElementById("completionMessage");

        if (completeWrapper !== null) {
            document.getElementById('subscriptionForm').addEventListener("submit", function(e){
                e.preventDefault();

                completeWrapper.classList.add('active');
                completeWrapper.classList.add('loading');

                var form = e.target;
                var data = new FormData(form);
                var request = new XMLHttpRequest();

                request.open(form.method, form.action);
                request.setRequestHeader('X-CSRF-Token', window.appConfig.token);
                request.send(data);

                console.log(request);

                request.addEventListener('load', function(evt) {
                    completeWrapper.classList.remove('loading');
                    completeWrapper.innerHTML = this.responseText;

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'subscriptionFormSubmit'
                    });
                });
            });
        }
    }
}
