import CookiesEuBanner from '../cookies-eu-banner/cookies-eu-banner';

if (document.getElementById('cookies-eu-banner')) {

    const acceptFunction = () => {
        window.appConfig.hasConsent = true;
    }

    const rejectFunction = () => {
        window.appConfig.hasConsent = false;
    }

    new CookiesEuBanner(acceptFunction, rejectFunction, true, true);
}
