export default class FAQ {
    constructor() {
        const elements = document.querySelectorAll('.element');

        elements.forEach(element => {
            let btn = element.querySelector('.question');
            let icon = element.querySelector('.question button i');
            var answer = element.lastElementChild;
            var answers = document.querySelectorAll('.element .answer');

            btn.addEventListener('click', () => {
                answers.forEach(ans => {
                    let ansIcon = ans.parentElement.querySelector('button i');
                    if (answer !== ans) {
                        ans.classList.add('hideText');
                        ansIcon.className = 'fas fa-chevron-down';
                    }
                });

                answer.classList.toggle('hideText');
                icon.className === 'fas fa-chevron-down' ? icon.className = 'fas fa-chevron-up' : icon.className ='fas fa-chevron-down';
            });
        });
    }
}
