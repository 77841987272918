export default class CountryPicker {
    constructor() {
        /** COUNTRY PICKER */
        var countryList = document.getElementById('country-select');
        var selects = document.getElementsByClassName('select-list');
        var languageLists = document.getElementsByClassName('language-select');

        if (countryList) {
            countryList.querySelectorAll('.list > li').forEach (countryEl => {
                countryEl.addEventListener('click', function(e) {
                    e.preventDefault();
                    let targetClass = 'language-'+ countryEl.dataset.country;
                    let goto = countryEl.dataset.goto;

                    if (goto) {
                        document.location.href = goto;

                        return;
                    }

                    for (let languageEl of languageLists) {
                        if (languageEl.classList.contains(targetClass)) {
                            languageEl.classList.add('active');
                        } else {
                            languageEl.classList.remove('active');
                        }
                    };

                    let label = countryEl.getElementsByClassName('name')[0];

                    countryList.getElementsByClassName('selected')[0].textContent = label.textContent;
                    countryList.classList.remove('open');
                });
            });

            for (let languageEl of languageLists) {
                languageEl.querySelectorAll('.list > li').forEach (langEl => {
                    langEl.addEventListener('click', function(e) {
                        countryList.classList.remove('open');
                    });
                });
            }

            for (let selectList of selects) {
                selectList.querySelectorAll('li .selected')[0].addEventListener('click', (e) => {
                    selectList.classList.toggle('open');
                });
            }
        }
    }
}
