import 'alpinejs'
import Axios from 'axios';

window.wishlists = function() {
    return {
        header: [],
        products: [],
        shareComplete: false,
        saveComplete: false,
        has_selection: false,
        has_checks: false,
        wishlist_link: null,
        filters: {
            subcategories: [],
            selection: { }
        },
        sortOrders: {},
        promosAllowed: window.promosAllowed,

        initialize() {
            this.fetchProducts();
        },

        sort(field) {
            const sortOrder = !this.sortOrders.hasOwnProperty(field) ? true : !this.sortOrders[field];

            this.sortOrders[field] = sortOrder;

            this.products.sort(function(a, b) {
                let x = a[field].toLowerCase();
                let y = b[field].toLowerCase();

                if (sortOrder) {
                    return x.localeCompare(y);
                } else {
                    return y.localeCompare(x);
                }
            });
        },

        filter(...fields) {
            this.filters.selection = {};

            // make some sets so we can have uniqueness
            let hasPromos = false;
            let subcategories = new Set();
            let fieldKey = fields.join('_');
            let x = 0;

            this.products.forEach((product) => {
                product.visible = false;
                product.checked = false;

                fields.forEach(field => {
                    if (product.hasOwnProperty(field)) {
                        let boolVal = product[field] == 1;

                        if (boolVal) {
                            product.visible = true;

                            subcategories.add(product.subcategory);

                            if (!hasPromos && product.promo_price && product.promo_price.length) {
                                hasPromos = true;
                            }
                        }
                    }
                });

                if (product.visible) {
                    // set combined field key
                    this.filters.selection[fieldKey] = 1;
                    // also set combined field key value
                    product[fieldKey] = 1;
                    // increment index
                    product.currentIndex = x;
                    x++;
                }
            });

            this.has_selection = true;

            // sets should be converted to arrays in order to sort them
            //this.filters.categories = Array.from(categories).sort();
            this.filters.subcategories = Array.from(subcategories).sort();

            if (this.promosAllowed && hasPromos) {
                this.filters.subcategories.unshift('Promotions');
            }

            this.$nextTick(() => {
                this.$refs.subcategory.value = "";

                window.scrollTo({
                    top: this.$refs.products.getBoundingClientRect().top + window.pageYOffset - 70,
                    behavior: 'smooth'
                });
            });
        },

        filterBy(field) {
            const promotionsValue = 'Promotions';
            let selected = this.$refs[field].value;

            this.filters.selection[field] = selected;

            if ((this.filters.selection.hasOwnProperty(field) && this.filters.selection[field] == "") || selected == promotionsValue) {
                delete this.filters.selection[field];
            }

            let x = 0;

            this.products.forEach((product) =>{
                product.visible = true;

                for (const [field, value] of Object.entries(this.filters.selection)) {
                    if ((product[field] !== value)) {
                        product.visible = false;
                        break;
                    }
                }

                if (selected == promotionsValue && product.promo_price && product.promo_price.length == 0) {
                    product.visible = false;
                }

                if (product.visible) {
                    product.currentIndex = x;
                    x++;
                }
            });
        },

        checkProduct(product) {
            product.checked = !product.checked;

            this.has_checks = this.getChecked().length > 0;
        },

        toggleChecks() {
            this.products.forEach(function(product) {
                if (product.visible) {
                    product.checked = !product.checked;
                }
            });

            this.has_checks = this.getChecked().length > 0;
        },

        // return list of visible and checked products
        getChecked() {
            let checkedProducts = [];

            this.products.forEach(function(product) {
                if (product.checked) {
                    checkedProducts.push(product.id);
                }
            });

            return checkedProducts;
        },

        submitShareForm() {
            if (this.$refs.shareForm.checkValidity()) {
                let checkedProducts = this.getChecked();
                let data = new FormData(this.$refs.shareForm);

                data.append('products', JSON.stringify(checkedProducts));
                data.append('locale', window.appConfig.locale);

                let options = {
                    url: '/api/wishlists/share.json',
                    method: 'post',
                    data: data,
                    headers: {
                        'X-CSRF-Token': window.appConfig.token
                    }
                };

                Axios(options)
                    .then((response) => {
                        if (response.data.hasOwnProperty('wishlist') && response.data.wishlist.id) {
                            this.shareComplete = true;
                            this.wishlist_link = response.data.link;

                            window.dataLayer.push({
                                'event':'wishlist',
                                'wishlist_form_action': 'share'
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },

        submitSaveForm() {
            if (this.$refs.saveForm.checkValidity()) {
                let checkedProducts = this.getChecked();
                let data = new FormData(this.$refs.saveForm);

                data.append('products', JSON.stringify(checkedProducts));
                data.append('locale', window.appConfig.locale);

                let options = {
                    url: '/api/wishlists/save.json',
                    method: 'post',
                    data: data,
                    headers: {
                        'X-CSRF-Token': window.appConfig.token
                    }
                };

                Axios(options)
                    .then((response) => {
                        if (response.data.hasOwnProperty('wishlist') && response.data.wishlist.id) {
                            this.saveComplete = true;
                            this.wishlist_link = response.data.link;

                            window.dataLayer.push({
                                'event':'wishlist',
                                'wishlist_form_action': 'save'
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },

        fetchProducts() {
            Axios.get('/api/wishlists/products.json?locale=' + window.appConfig.locale)
                .then((response) => {
                    response.data.products.forEach((product) => {
                        product.visible = true;
                        product.checked = false;
                    });

                    this.products = response.data.products;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
};
